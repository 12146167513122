<template lang="pug">
  v-card(max-width="500" min-width="400px").auth-card
    v-card-text
      app-form(:model="model" ref="form" :formErrors="formErrors" :loading="loading" @submit="sendLoginRequest")
</template>

<script>
import { mapActions } from "vuex"

import AuthModel from "@/app/auth/core/auth-model"
import FormErrors from "@/util/form-errors"
import { REQUIRED } from "@/util/validation-rules";
import EventBus from "@/util/event-bus";
import { EVENT_BUS } from "@/util/const";
import ErrMixin from "@/mixins/errors.mixin"
import Auth from "@/services/CRMinitAuth"

export default {
  name: 'Login',

  mixins: [ ErrMixin ],

  data: () => ({
    model: null,
    formErrors: null,
    RULES: {
      REQUIRED
    },
    loading: false
  }),

  created() {
    this.init()
  },

  methods: {
    ...mapActions({
      getCurrentUserInfo: 'user/current'
    }),

    init() {
      this.formErrors = new FormErrors()
      this.model = new AuthModel(this.formErrors);
    },

    async sendLoginRequest() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      try {
        this.loading = true
        await Auth.updateTokensByCredentials(this.model.getApiData())
        await this.getCurrentUserInfo()
        if (this.$route.query.returnURL) await this.$router.push({ name: this.$route.query.returnURL })
        else await this.$router.push({ name: this.$ROUTER_NAMES.CANDIDATES })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.processError(err)
      }
    },
  },

  components: {
    appForm: () => import('@/app/auth/components/LoginForm.vue')
  }
}
</script>